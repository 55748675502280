/** @format */
$primary-pink: #f7c1b8;
$primary-green: #9be3be;

// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }

body {
  background-color: #202020;
  background-image: url(../assets/images/hacker.gif);
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    // background-image: url(../assets/images/hero-bg.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

a,
a:visited,
a:focus,
button {
  color: #000000;
}

#root {
  max-width: 100%;
}

.App {
  display: flex;
  gap: 4rem;
  align-items: center;

  &.mobile {
    gap: 0;
  }
}

.scoreboard {
  text-align: left;
  color: white;
  max-width: 375px;

  .logo {
    width: 100%;
    font-size: 1.5rem;
    margin-bottom: 4rem;

    h2 {
      &:last-child {
        text-align: right;
      }
    }

    .divider {
      height: 4px;
      background-color: white;
      width: 100%;
      margin: 0.5rem 0;
    }
  }
  // width: 100%;
  // padding: 0.5rem;
  // box-shadow: -15px 0 0 0 white, 15px 0 0 0 white, 0 -15px 0 0 white,
  //   0 15px 0 0 white;
  //  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
  }
}

.your-rank {
  padding-top: 1rem;
}

#scoreB {
  list-style: none;

  li {
    display: flex;
    justify-content: space-between;
    font-size: 1.75rem;
    padding-bottom: 0.2rem;

    .score-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

h3 {
  font-size: 3.5rem;
  margin-bottom: 2rem;
}

button {
  padding: 1rem 2rem;
  background-color: $primary-green;
  border: 4px solid black;
  font-size: 2.5rem;
  margin: 0.5rem;
  cursor: pointer;
  display: inline-block;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 1px 3px 0 black;
  }
}

.full-w {
  max-width: 100%;
}

#how-to-play {
  margin: 0;
  padding: 2rem 2rem;
  width: 100%;
  height: 100vh;
  min-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  // background-image: url(../assets/images/office-bg.png);
  background-color: $primary-pink;
  overflow-y: scroll;

  .scoreboard {
    color: #202020;

    .logo {
      .divider {
        background-color: #202020;
      }
    }
  }

  h3 {
    font-size: 2rem;
  }

  button {
    margin-top: 2rem;
    display: block;
  }

  p {
    font-size: 1.5rem;
    margin-bottom: 0.6rem;
  }

  &.comic-view {
    justify-content: start;

    .close {
      font-size: 2rem;
      cursor: pointer;
      padding-bottom: 5rem;
    }

    ul {
      list-style: none;
      display: flex;
      gap: 1rem;
      flex-direction: column;
      padding-bottom: 3rem;
      
      li {

        img {
          max-width: 100%;
        }
      }
    }
  }

  &.cutscene-start {
    background-image: url(../assets/images/cutscene-bg.png);
    background-color: #000000;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 8rem;
    background-position: center;
    background-attachment: fixed;
    background-size: 100%;
    background-repeat: no-repeat;

    h3 {
      font-size: 2rem;
      color: white;
      opacity: 0;
      animation: fadeIn 0.5s 1s forwards;
    }

    @keyframes fadeIn {
      to {
        opacity: 1;
      }
    }

    @media only screen and (min-width: 768px) {

      &.cutscene-start {
        background-image: url(../assets/images/cutscene-bg.png);
        align-items: center;
        justify-content: flex-end;
        background-size: auto;
        padding-bottom: 4rem;
        background-position: left center;
        background-attachment: scroll;
      }

      h3 {
        font-size: 2rem;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    margin: 20px auto;
    padding: 4rem 8rem;
    width: 768px;
    height: 720px;
    justify-content: center;
    min-width: auto;
  }
}

#home {
  margin: 0;
  padding: 1rem;
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url(../assets/images/office-2.gif);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-color: $primary-pink;

  .scores-hacked {
    color: red;
    font-size: 2rem;
    width: 70%;
    text-align: center !important;
  }

  .comic {
    margin-top: .8rem;

    a {
      font-size: 1.6rem;
      cursor: pointer;
    }
  }

  h3 {
    font-size: 3rem;
    margin-bottom: 2.2rem;
  }

  .logo {
    margin-top: 4rem;
    width: 100%;
    font-size: 1.5rem;
    margin-bottom: 3.5rem;

    h2 {
      &:last-child {
        text-align: right;
      }
    }

    .divider {
      height: 4px;
      background-color: black;
      width: 100%;
      margin: 0.5rem 0;
    }
  }

  .home-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    button {
      width: 100%;
    }
  }

  .credits {
    position: absolute;
    bottom: 1.25rem;
    max-width: 100%;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
  }

  @media only screen and (min-width: 768px) {
    margin: 20px auto;
    padding: 4rem 0;
    width: 768px;
    height: 720px;

    .logo {
      width: 65%;
      font-size: 2rem;
      margin-bottom: 2rem;
      margin-top: 0;

      h2 {
        &:last-child {
          text-align: right;
        }
      }

      .divider {
        height: 4px;
        background-color: black;
        width: 100%;
        margin: 0.5rem 0;
      }
    }

    .home-buttons {
      width: 75%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      .top-scores-page {
        display: none;
      }
    }
  }
}

#game-over {
  margin: 0;
  padding: 1rem;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  // justify-content: center;
  flex-direction: column;
  // background-image: url(../assets/images/office-bg.png);
  background-color: $primary-pink;
  min-height: 100vh;

  h3 {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }

  & > div {
    width: 100%;
    text-align: left;
  }

  label {
    text-align: left;
    font-size: 1.6rem;
  }

  input {
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 1.5rem;
    margin-bottom: 0.6rem;
    border: 4px solid black;
  }

  .buttons-game-over {
    margin-top: 0.4rem;
    margin-bottom: 0.8rem;
    text-align: center;

    button {
      display: inline-block;
      padding: 0.5rem 0;
      width: 100%;
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .back-to-home {
    text-align: center;
    font-size: 2rem;
    cursor: pointer;
  }

  .game-over-heading {
    font-size: 4rem;
    margin-bottom: 0.5rem;
    text-align: center;

    .divider {
      height: 4px;
      background-color: black;
      width: 100%;
      margin: 0.5rem 0;
    }
  }

  .credits {
    position: absolute;
    bottom: 1.25rem;
    left: 50%;
    transform: translateX(-50%);
  }

  @media only screen and (min-width: 768px) {
    margin: 20px auto;
    padding: 2rem 0;
    width: 768px;
    height: 720px;
    min-height: auto;
    justify-content: center;

    .game-over-heading {
      font-size: 6rem;
      margin-bottom: 0.5rem;
      text-align: center;

      .divider {
        height: 4px;
        background-color: black;
        width: 100%;
        margin: 0.5rem 0;
      }
    }

    p {
      width: 75%;
    }

    & > div {
      width: 80%;
      text-align: left;
    }

    label {
      text-align: left;
      font-size: 2rem;
    }

    input {
      width: 100%;
      padding: 0.75rem 1rem;
      font-size: 1.5rem;
      margin-bottom: 1rem;
      border: 4px solid black;
    }

    .buttons-game-over {
      margin-top: 0.8rem;
      margin-bottom: 1rem;
      text-align: center;

      button {
        padding: 1rem 2rem;
        display: inline-block;
        width: auto;
        margin: 1rem;
      }
    }
  }
}

#canvas-container {
  cursor: none;
  // width: 768px;
  // height: 720px;
  position: relative;
  // background-image: url(../assets/images/bg-duckhunt.png);
  background-color: $primary-pink;
  box-shadow: 12px 12px 24px rgba($color: #000000, $alpha: 0.5);
}

$color_1: #9be3be;
$color_2: white;
$color_3: #f7c1b8;
$background-color_1: black;

#canvas-container {
  #round-counter {
    pointer-events: none;
    position: absolute;
    bottom: 115px;
    left: 6px;
    background-color: $background-color_1;
    padding-left: 4px;
    font-size: 50px;
    line-height: 30px;
    display: inline-block;
    color: $color_1;
    
    @media only screen and (min-width: 768px) {
      font-size: 70px;
      left: 63px;
    }
  }
  #hit-counter {
    pointer-events: none;
    position: absolute;
    bottom: 12px;
    left: 15px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    h2 {
      font-size: 60px;
      color: $color_1;
    }
    ul {
      padding-top: 2px;
      list-style: none;
      display: flex;
      gap: 3px;
    }
    #hit-counter__enemies {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
    #hit-counter__pass-limit {
      color: $color_2;
      font-size: 25px;
      transform: translateY(-5px);
    }
    @media only screen and (min-width: 768px) {
      bottom: 43px;
      left: 195px;
    }
  }
  #bullets-container {
    pointer-events: none;
    position: absolute;
    bottom: 92px;
    left: 104px;

    #bullets {
      list-style: none;
      display: flex;
      gap: 12px;
      li {
        img {
          width: 12px;
          height: 21px;
        }
      }
    }
    h2 {
      color: $color_3;
      font-size: 38px;
      line-height: 20px;
    }

    @media only screen and (min-width: 768px) {
      bottom: 49px;
		  left: 77px;
    }
  }
  #score-counter {
    pointer-events: none;
    position: absolute;
    bottom: 90px;
    left: 207px;
    color: $color_2;
    text-align: right;
    h2 {
      font-size: 60px;
      line-height: 28px;
    }

    @media only screen and (min-width: 768px) {
      left: 588px;
      bottom: 47px;
    }
  }
}

#canvas-container #between-rounds-scoreboard {
  font-size: 40px;
  line-height: 28px;
  text-align: center;
  position: absolute;
  padding: 20px;
  background-color: black;
  border-radius: 3px;
  border: 3px solid white;
  color: white;
  width: 600px;
  top: 50px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  .between-rounds-scoreboard__score-item {
    padding-bottom: 10px;
  }

  .between-rounds-scoreboard__next-round {
    animation: blink 1s infinite;
    padding-top: 20px;
    text-align: center;
  }
}

#no-bullets-left {
  font-size: 40px;
  line-height: 28px;

  position: absolute;
  padding: 20px;
  background-color: black;
  border-radius: 3px;
  border: 3px solid white;
  color: white;
  width: fit-content;
  top: 250px;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.page-settings {
  color: white;
  font-size: 25px;
}

.cutscene-game-over {
  text-align:  center;
  h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  h4 {
    font-size: 2rem;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
