/* @font-face {
    font-family: "000webfontregular";
    src: url("./assets/fonts/000webfont-webfont.woff2") format("woff2"),
      url("./assets/fonts/000webfont-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  * {
    font-family: "000webfontregular";
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */

  @font-face {
    font-family: "000webfontregular";
    src: url("./assets/fonts/000webfont-webfont.woff2") format("woff2"),
      url("./assets/fonts/000webfont-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'm29_duck_houndregular';
    src: url('./assets/fonts/m29-webfont.woff2') format('woff2'),
         url('./assets/fonts/m29-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

  * {
    font-family: "000webfontregular";
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  h2.title {
    font-family: 'm29_duck_houndregular';
  }